<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="datas"
      :search="search"
      mobile-breakpoint="960"
      @click:row="(item) => $emit('edititem', item)"
    >
      <template v-slot:[`item.amount`]="{ item }">
        <span v-if="item.type != 1">
          <v-chip
            dark
            small
            class="mt-2"
            v-if="item.in_out == 2 && item.type == 2"
            color="red"
          >
            {{ $vuetify.lang.t("$vuetify.expense") }}
          </v-chip>

          <v-chip
            dark
            small
            class="mt-2"
            v-else-if="item.in_out == 1 && item.type == 2"
            color="blue"
          >
            {{ $vuetify.lang.t("$vuetify.income") }}
          </v-chip>

          <v-chip
            dark
            small
            class="mt-2"
            v-else-if="item.in_out == 2 && item.type == 3"
            color="red"
          >
            {{ $vuetify.lang.t("$vuetify.withdraw") }}
          </v-chip>

          <v-chip
            dark
            small
            class="mt-2"
            v-else-if="item.in_out == 1 && item.type == 3"
            color="blue"
          >
            {{ $vuetify.lang.t("$vuetify.deposit") }}
          </v-chip>
        </span>
        <div class="my-2">
          <span>USD - $ {{ formatNumber(item.amount) }}</span> |
          <span>KHR - &#6107; {{ formatNumber(item.kh) }}</span>
        </div>
      </template>

      <template v-slot:[`item.category`]="{ item }">
        <v-chip color="#e91e63" small dark v-if="item.type == 1">
          {{ $vuetify.lang.t("$vuetify.bankStatements") }}
        </v-chip>

        <v-chip color="#fb8c00" small dark v-else-if="item.type == 2">
          {{ $vuetify.lang.t("$vuetify.xianjingshouzhi") }}
        </v-chip>

        <v-chip color="#9c27b0" small dark v-else>
          {{ $vuetify.lang.t("$vuetify.yinghangcunqu") }}
        </v-chip>
      </template>

      <template v-slot:[`item.details`]="{ item }">
        <span v-if="item.type != 2">
          <div>Bank Name - {{ item.bank_name }}</div>
          <div>Bank Account - {{ item.bank_account }}</div>
        </span>

        <span v-else-if="item.type == 2">
          <div>Remark - {{ item.note }}</div>
        </span>
      </template>

      <template v-slot:[`item.gen_time`]="{ item }">
        {{ item.gen_time ? item.gen_time.substr(0, 10) : "" }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="green"
              @click.stop="$emit('edititem', item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('del')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('deleteitem', item)"
              color="red"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('add')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('copyitem', item)"
              color="blue"
              v-bind="attrs"
              v-on="on"
              class="mx-2"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.copy") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('historyitem', item)"
              color="black"
              v-bind="attrs"
              v-on="on"
            >
              mdi-history
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.history") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        {{ checkStrLength(item.note) }}
        <span class="blue--text" v-if="item.note.length > 30">.....more</span>
      </template>

      <template v-slot:[`item.address`]="{ item }">
        {{ checkStrLength(item.address) }}
        <span class="blue--text" v-if="item.address.length > 30">
          .....more
        </span>
      </template>
    </v-data-table>

    <div
      class="px-2"
      style="margin-top: -40px"
      v-if="showStats == 2 || showStats == 3"
    >
      <v-chip
        v-if="getTotalIncome != 0"
        dark
        class="my-0 mr-4 display-1 pa-5"
        color="blue"
      >
        {{
          showStats == 2
            ? $vuetify.lang.t("$vuetify.income")
            : $vuetify.lang.t("$vuetify.deposit")
        }}: $ {{ formatNumber(this.getTotalIncome) }}
      </v-chip>

      <v-chip
        v-if="getTotalExpend != 0"
        dark
        class="my-0 display-1 pa-5"
        color="red"
      >
        {{
          showStats == 2
            ? $vuetify.lang.t("$vuetify.expense")
            : $vuetify.lang.t("$vuetify.withdraw")
        }}: $ {{ formatNumber(this.getTotalExpend) }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinancialTable",
  props: {
    headers: Array,
    datas: Array,
    search: String,
    showStats: Number,
  },
  methods: {
    checkStrLength(item) {
      if (item.length > 30) {
        return item.substring(0, 30);
      } else {
        return item;
      }
    },
  },
  computed: {
    getTotalIncome() {
      var totalIncome = 0;

      this.datas.forEach((p) => {
        if (p.in_out == 1) {
          totalIncome += p.amount;
        }
      });

      return totalIncome.toFixed(2);
    },
    getTotalExpend() {
      var totalExpend = 0;

      this.datas.forEach((p) => {
        if (p.in_out == 2) {
          totalExpend += p.amount;
        }
      });

      return totalExpend.toFixed(2);
    },
  },
};
</script>
