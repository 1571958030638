var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.datas,"search":_vm.search,"mobile-breakpoint":"960"},on:{"click:row":function (item) { return _vm.$emit('edititem', item); }},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.type != 1)?_c('span',[(item.in_out == 2 && item.type == 2)?_c('v-chip',{staticClass:"mt-2",attrs:{"dark":"","small":"","color":"red"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.expense"))+" ")]):(item.in_out == 1 && item.type == 2)?_c('v-chip',{staticClass:"mt-2",attrs:{"dark":"","small":"","color":"blue"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.income"))+" ")]):(item.in_out == 2 && item.type == 3)?_c('v-chip',{staticClass:"mt-2",attrs:{"dark":"","small":"","color":"red"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.withdraw"))+" ")]):(item.in_out == 1 && item.type == 3)?_c('v-chip',{staticClass:"mt-2",attrs:{"dark":"","small":"","color":"blue"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.deposit"))+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"my-2"},[_c('span',[_vm._v("USD - $ "+_vm._s(_vm.formatNumber(item.amount)))]),_vm._v(" | "),_c('span',[_vm._v("KHR - ៛ "+_vm._s(_vm.formatNumber(item.kh)))])])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [(item.type == 1)?_c('v-chip',{attrs:{"color":"#e91e63","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.bankStatements"))+" ")]):(item.type == 2)?_c('v-chip',{attrs:{"color":"#fb8c00","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.xianjingshouzhi"))+" ")]):_c('v-chip',{attrs:{"color":"#9c27b0","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.yinghangcunqu"))+" ")])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [(item.type != 2)?_c('span',[_c('div',[_vm._v("Bank Name - "+_vm._s(item.bank_name))]),_c('div',[_vm._v("Bank Account - "+_vm._s(item.bank_account))])]):(item.type == 2)?_c('span',[_c('div',[_vm._v("Remark - "+_vm._s(item.note))])]):_vm._e()]}},{key:"item.gen_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.gen_time ? item.gen_time.substr(0, 10) : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edititem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])]),(_vm.permissionCheck('del'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])]):_vm._e(),(_vm.permissionCheck('add'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('copyitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.copy")))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"black"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('historyitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-history ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.history")))])])]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.note))+" "),(item.note.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more")]):_vm._e()]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.address))+" "),(item.address.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(" .....more ")]):_vm._e()]}}],null,true)}),(_vm.showStats == 2 || _vm.showStats == 3)?_c('div',{staticClass:"px-2",staticStyle:{"margin-top":"-40px"}},[(_vm.getTotalIncome != 0)?_c('v-chip',{staticClass:"my-0 mr-4 display-1 pa-5",attrs:{"dark":"","color":"blue"}},[_vm._v(" "+_vm._s(_vm.showStats == 2 ? _vm.$vuetify.lang.t("$vuetify.income") : _vm.$vuetify.lang.t("$vuetify.deposit"))+": $ "+_vm._s(_vm.formatNumber(this.getTotalIncome))+" ")]):_vm._e(),(_vm.getTotalExpend != 0)?_c('v-chip',{staticClass:"my-0 display-1 pa-5",attrs:{"dark":"","color":"red"}},[_vm._v(" "+_vm._s(_vm.showStats == 2 ? _vm.$vuetify.lang.t("$vuetify.expense") : _vm.$vuetify.lang.t("$vuetify.withdraw"))+": $ "+_vm._s(_vm.formatNumber(this.getTotalExpend))+" ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }